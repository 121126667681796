import { Box, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import TextField from '../../components/inputs/TextField';
import { ERoutes } from '../../enums/routes.enum';
import MainLayout from '../../layouts/MainLayout';
import { getQueryParamsAsString } from '../../utils/helpers/getQueryParamsAsString';
import './ExternalFormPage.scss';

const defaultValues = { partner_name: 'holodilnik' };

function ExternalFormPage() {
    const navigate = useNavigate();
    const [categoryId, setCategoryId] = useState<string | null>(null);
    const [brandId, setBrandId] = useState<string | null>(null);
    const [deviceId, setDeviceId] = useState<string | null>(null);
    const [partnerName, setPartnerName] = useState<string | null>(defaultValues.partner_name);
    const [iframeParams, setIframeParams] = useState<string | null>(
        `?is_new_price_lists=${process.env.REACT_APP_NEW_PRICES_ENABLED || 'false'}&partner_name=${
            defaultValues.partner_name
        }`,
    );

    useEffect(() => {
        if (!process.env.REACT_APP_URL_EXTERNAL_FORM) {
            navigate(ERoutes.Home);
        }
    }, []);

    function updateHandler() {
        const queries = getQueryParamsAsString([
            { category_id: categoryId },
            { brand_id: brandId },
            { device_id: deviceId },
            { partner_name: partnerName },
            { is_new_price_lists: process.env.REACT_APP_NEW_PRICES_ENABLED || 'false' },
        ]);
        setIframeParams(queries);
    }

    return (
        <MainLayout>
            <div className="external-form-page">
                <Box display="flex">
                    <TextField
                        name="category_id"
                        placeholder="category_id"
                        onChange={(e) => {
                            setCategoryId(e.target.value);
                        }}
                    />
                    <TextField
                        name="brand_id"
                        placeholder="brand_id"
                        onChange={(e) => {
                            setBrandId(e.target.value);
                        }}
                    />
                    <TextField
                        name="device_id"
                        placeholder="device_id"
                        onChange={(e) => {
                            setDeviceId(e.target.value);
                        }}
                    />
                    <TextField
                        name="partner_name"
                        placeholder="partner_name"
                        onChange={(e) => {
                            setPartnerName(e.target.value);
                        }}
                        defaultValue={defaultValues.partner_name}
                    />
                    <Button onClick={updateHandler}>Обновить</Button>
                </Box>
                <iframe
                    key={iframeParams}
                    src={`${process.env.REACT_APP_URL_EXTERNAL_FORM}${iframeParams || ''}`}
                    title="external-form"
                    style={{
                        width: '100%',
                        border: 'none',
                        minWidth: '280px',
                        minHeight: '500px',
                        overflow: 'hidden',
                        height: '1334px',
                    }}
                />
            </div>
        </MainLayout>
    );
}

export default ExternalFormPage;
