import { styled } from '@mui/material';

export const Time = styled('div')({
    fontSize: '12px',
    color: '#878EA5',
});

export const UserDataWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    gap: '10px',
    marginBottom: '6px',
});

export const ChangeTitle = styled('div')({
    fontSize: '12px',
    color: '#878EA5',
});

export const TimeWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    fontSize: '12px',
    color: '#878EA5',
});

export const HistoryWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    margin: '12px 0',
});

export const ThreeDotsWrapper = styled('div')({
    cursor: 'pointer',
    padding: '0 10px',
    height: '20px',
});
