import { issueStatusesMock } from 'mockData/issues';
import { EditSmall } from 'static/Icons/EditSmall';
import { ThreeDots } from 'static/Icons/ThreeDots';
import { useAppDispatch } from 'store/hooks';
import { dateFormatter } from 'utils/helpers/dateFormatter';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Chip, Divider } from '@mui/material';
import { ICommentsHistory } from 'interfaces/history';
import { FC } from 'react';

import { Comment, EditIconWrapper, HistoryItem, HistoryItemWrapper, Statuses } from './styles';

import { setChooseDelete } from '../../store';
import { ThreeDotsWrapper, Time } from '../styles';

type Props = {
    historyItem: ICommentsHistory;
    setIsHistoryComment: (value: any) => void;
    setOpenMenu: (value: any) => void;
};

export const DefaultHistoryItem: FC<Props> = ({ historyItem, setIsHistoryComment, setOpenMenu }) => {
    const dispatch = useAppDispatch();

    const shortFormat: Intl.DateTimeFormatOptions = {
        day: 'numeric',
        month: 'numeric',
    };

    const formatForChecking: Intl.DateTimeFormatOptions = {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
    };

    const getTimeWindow = (start: string, end: string) => {
        if (!start || !end) return;

        const date = start.split('T')[0].replaceAll('-', '.');
        const times = `${start.split('T')[1].slice(0, 5)}−${end.split('T')[1].slice(0, 5)}`;

        const temp = date.split('.');
        const year = temp[0].split('');
        year.splice(0, 2);
        temp[0] = year.join('');

        return `${temp.reverse().join('.')} ${times}`;
    };

    const isHistoryCommentUpdated =
        dateFormatter(historyItem.data?.comments?.[0]?.created_at as string, {}, formatForChecking) !==
        dateFormatter(historyItem.data?.comments?.[0]?.updated_at as string, {}, formatForChecking);

    return (
        <HistoryItemWrapper key={historyItem.data.id}>
            <HistoryItem>
                <div>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <Time>{dateFormatter(historyItem.data.created_at, {}, shortFormat)}</Time>

                        {historyItem.object_type === 'comment' ? (
                            <>
                                {dateFormatter(historyItem.data?.created_at, {}, formatForChecking) !==
                                dateFormatter(historyItem.data?.updated_at as string, {}, formatForChecking) ? (
                                    <EditSmall />
                                ) : null}
                                <Comment>{historyItem.data?.comment}</Comment>
                            </>
                        ) : null}

                        {historyItem.object_type === 'history' && historyItem?.data?.trigger === 'status' ? (
                            <>
                                <Statuses>
                                    <ArrowForwardIcon color="disabled" />
                                    <Chip
                                        label={issueStatusesMock[historyItem.data.new_value]?.title}
                                        sx={{
                                            fontWeight: '600',
                                            backgroundColor: `${
                                                issueStatusesMock[historyItem.data.new_value]?.colorHex
                                            }`,
                                            color: `${
                                                issueStatusesMock[historyItem.data.new_value]?.colorText
                                            } !important`,
                                        }}
                                        size={'small'}
                                    />
                                </Statuses>
                            </>
                        ) : null}

                        {historyItem.object_type === 'history' && historyItem?.data?.trigger === 'time_windows' ? (
                            <div>
                                <Statuses>
                                    <ArrowForwardIcon color="disabled" />
                                    <Chip
                                        label={getTimeWindow(
                                            historyItem?.data?.extra_data?.find((data) => data.type === 'new')
                                                ?.start_datetime!,
                                            historyItem?.data?.extra_data?.find((data) => data.type === 'new')
                                                ?.end_datetime!,
                                        )}
                                        size={'small'}
                                    />
                                </Statuses>
                            </div>
                        ) : null}
                    </div>
                    {historyItem?.data?.comments?.length
                        ? historyItem?.data?.comments.map((commentItem) => {
                              return (
                                  <div
                                      style={{ maxWidth: '220px' }}
                                      key={commentItem?.id}
                                  >
                                      <Comment>{commentItem?.comment}</Comment>
                                      {isHistoryCommentUpdated ? (
                                          <EditIconWrapper>
                                              <EditSmall />
                                          </EditIconWrapper>
                                      ) : null}
                                  </div>
                              );
                          })
                        : null}
                </div>
                <ThreeDotsWrapper
                    onClick={(e) => {
                        setOpenMenu(e.target);
                        if (historyItem.object_type === 'history') {
                            dispatch(setChooseDelete(historyItem.data?.comments?.[0]?.id || historyItem.data.id));
                            setIsHistoryComment(historyItem.data?.comments?.[0] || {});
                        } else {
                            dispatch(setChooseDelete(historyItem.data.id));
                            setIsHistoryComment(false);
                        }
                    }}
                >
                    <ThreeDots height={16} />
                </ThreeDotsWrapper>
            </HistoryItem>
            <Divider sx={{ marginTop: '5px' }} />
        </HistoryItemWrapper>
    );
};
