import ButtonGroup, { IButtonGroupItem } from 'components/ButtonGroup';
import Loader from 'components/Loader';
import { EIssueStatus } from 'enums/issues.enum';
import { ERoutes } from 'enums/routes.enum';
import Reminder from 'modules/Reminder';
import { getClientThunk } from 'store/actions/clients';
import { setIsOpenCreateIssueAction } from 'store/actions/create/createIssue';
import {
    setIsOpenCreateIssueReminderAction,
    setIssueIdCreateIssueReminderAction,
} from 'store/actions/create/createIssueReminder';
import { setIsOpenEditIssueStatusAction, setIssueIdEditIssueStatusAction } from 'store/actions/edit/editIssueStatus';
import { putIssueArchiveThunk } from 'store/actions/issues';
import { useAppDispatch } from 'store/hooks';
import { chatsTotalUnseenCountIssuesSelector } from 'store/selectors/chatsSelectors';
import { clientSelector } from 'store/selectors/clientsSelectors';
import { issueSelector } from 'store/selectors/issuesSelectors';
import { profileSelector } from 'store/selectors/profileSelectors';
import defaultErrorCallback from 'utils/helpers/defaultErrorCallback';
import { getErrorString } from 'utils/helpers/getErrorString';
import { timeWindowsFormatter } from 'utils/helpers/timeWindowsFormatter';

import { Chat as ChatIcon } from '@mui/icons-material';
import { Box, Typography, Fab, Badge } from '@mui/material';
import Paper from '@mui/material/Paper';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import Chat from './Chat';
import ClientReview from './ClientReview';
import DeviceProblem from './DeviceProblem';
import Diagnostic from './Diagnostic';
import Documents from './Documents';
import IssueAddress from './IssueAddress';
import IssueClient from './IssueClient';
import IssuePrices from './IssuePrices';
import IssueStatus from './IssueStatus';
import IssueWorker from './IssueWorker';
import NotesMaster from './NotesMaster';
import ObservesIssue from './ObservesIssue';
import PartsOrders from './PartsOrders';
import PhotosMaster from './PhotosMaster';
import { ReasonRefusal } from './ReasonRefusal';

import DeleteIssueReminder from '../DeleteIssueReminder';
import EditIssueReminder from '../EditIssueReminder';

import './IssueDetail.scss';

function IssueDetail() {
    const { issueId } = useParams();

    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const profile = useSelector(profileSelector);
    const issueClient = useSelector(clientSelector);
    const issue = useSelector(issueSelector);
    const countChats = useSelector(chatsTotalUnseenCountIssuesSelector);

    const [isChatOpen, setChatOpen] = useState(false);

    const archiveIsVisible = useMemo(() => {
        switch (issue?.status) {
            case EIssueStatus.Canceled: {
                return false;
            }
            case EIssueStatus.Solved: {
                return false;
            }
            default:
                return true;
        }
    }, [issue?.status]);

    function getButtonGroupItems(): IButtonGroupItem[] {
        const buttonGroupItems: IButtonGroupItem[] = [
            {
                title: 'Скопировать информацию',
                handler: () => copyInformation(),
            },
            {
                title: 'Создать напоминание',
                handler: () => openCreateReminderModal(),
            },
            {
                title: 'Создать копию',
                handler: () => showDialogIssueCopy(),
            },
            {
                title: issue?.is_archived ? 'Убрать из архива' : 'Перенести в архив',
                handler: () => changeIssueArchive(),
                disabled: archiveIsVisible,
            },
        ];

        if (profile?.is_superadmin) {
            buttonGroupItems.push({
                title: 'Изменить статус',
                handler: () => openChangeStatusModal(),
            });
        }

        return buttonGroupItems;
    }

    function showDialogIssueCopy() {
        dispatch(setIsOpenCreateIssueAction(true));
    }

    async function copyInformation() {
        if (!issue?.address && issue?.client) {
            await dispatch(getClientThunk(issue.client.id));
        }
        if (issue) {
            const issueInfo = [
                issue.oid,
                issue.client?.first_name,
                issue.client?.last_name,
                issue.client?.middle_name,
                issue.client?.phone_number,
                issue.sub_issues?.[0].home_device?.category?.name,
                issue.sub_issues?.[0].home_device?.brand?.name,
                issue.description || issue.comment,
                issue?.address || issueClient?.addresses?.[0]?.address,
                timeWindowsFormatter(issue.time_windows) || 'Не выбрана',
            ]
                .filter((e) => e)
                .join(' / ');

            await navigator.clipboard.writeText(issueInfo);
        }
    }

    function openCreateReminderModal() {
        if (issue) {
            dispatch(setIssueIdCreateIssueReminderAction(issue.id));
            dispatch(setIsOpenCreateIssueReminderAction(true));
        }
    }

    function openChangeStatusModal() {
        if (issue) {
            dispatch(setIssueIdEditIssueStatusAction(issue.id));
            dispatch(setIsOpenEditIssueStatusAction(true));
        }
    }

    async function changeIssueArchive() {
        try {
            if (!issue || !issueId) return;
            await dispatch(putIssueArchiveThunk({ issueId, data: { is_archived: !issue.is_archived } }));
            navigate(issue.is_archived ? `/${ERoutes.Archive}` : `/${ERoutes.Issues}`);
        } catch (err) {
            defaultErrorCallback({ errorMessage: getErrorString({ err }) });
        }
    }

    useEffect(() => {
        if (!issue) return;
        switch (issue.is_archived) {
            case true: {
                navigate(`/${ERoutes.Archive}/${issue.id}`);
                break;
            }
            default:
                break;
        }
    }, [issue?.is_archived]);

    return (
        <Paper className="issue-detail-wrapper">
            {issue ? (
                <>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            gap={2}
                        >
                            <Typography variant="h6">№ {issue.oid}</Typography>
                            <ObservesIssue />
                            <Reminder />
                            <EditIssueReminder />
                            <DeleteIssueReminder />
                        </Box>
                        <Box
                            display="flex"
                            alignItems="center"
                        >
                            <Box
                                display="flex"
                                justifyContent="space-between"
                            >
                                <IssueStatus />
                            </Box>
                            <Box>
                                <ButtonGroup
                                    title="Меню"
                                    buttonType="dots"
                                    buttonsInfo={getButtonGroupItems()}
                                />
                            </Box>
                        </Box>
                    </Box>
                    {issue.status === 'canceled' && <ReasonRefusal issueStatus={issue.status} />}
                    <DeviceProblem />
                    <Diagnostic />
                    <IssueWorker />
                    <IssueClient />
                    <IssueAddress />
                    <PartsOrders />
                    <IssuePrices />
                    <Documents />
                    <PhotosMaster />
                    <NotesMaster />
                    {issue.status !== 'canceled' && <ClientReview />}
                </>
            ) : (
                <Loader />
            )}

            {isChatOpen && (
                <Chat
                    onClose={() => setChatOpen(false)}
                    issue={issue}
                />
            )}

            <Fab
                onClick={() => setChatOpen((p) => !p)}
                className="chat-btn"
            >
                <Badge
                    badgeContent={countChats}
                    color="error"
                >
                    <div className="badge-inner">
                        <ChatIcon className="icon" />
                    </div>
                </Badge>
            </Fab>
        </Paper>
    );
}

export default IssueDetail;
