import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, Drawer, IconButton, styled } from '@mui/material';

export const TitleWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
});

export const MuiIconButton = styled(IconButton)(({ isOpen }: { isOpen: boolean }) => ({
    width: '24px',
    height: '24px',
    background: isOpen ? '#2962FF' : 'none',
    boxShadow: isOpen ? '1px 1px 3px rgba(0,0,0,0.25)' : 'none',

    '&:hover': {
        background: isOpen ? '#2962FF' : 'none',
    },
}));

export const MuiArrowIcon = styled(ArrowBackIcon)(({ isOpen }: { isOpen: boolean }) => ({
    width: '20px',
    height: '20px',
    color: isOpen ? 'white' : 'black',
    rotate: isOpen ? '180deg' : '0deg',
    transition: 'all easy 300ms',
}));

export const Title = styled('div')({
    marginLeft: '5px',
    fontSize: '16px',
});

export const MuiDrawer = styled(Drawer)({
    '.MuiDrawer-paper': {
        padding: '15px',
        marginTop: '80px',
        height: 'calc(100vh - 80px)',
        borderRadius: '8px 0 0 8px',
        width: '470px',
    },
});

export const HistoryWrapper = styled('div')(({ isOpen }: { isOpen: boolean }) => ({
    width: isOpen ? '470px' : '280px',
    transform: isOpen ? 'translateX(-40px)' : 'none',
    transition: 'all easy 300ms',
}));

export const CloseIconWrapper = styled(Box)({
    cursor: 'pointer',
    padding: '0 4px',
    marginRight: '5px',
});

export const MuiBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
});

export const AddButton = styled(Button)({
    color: 'black',
    minWidth: '30px',
});
