import { styled } from '@mui/material';

export const HistoryItemWrapper = styled('div')({
    padding: '10px 5px',
});

export const Statuses = styled('div')({
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: '10px',
    rowGap: '5px',
    marginBottom: '5px',
});

export const Comment = styled('span')({
    fontSize: '12px',
    overflowWrap: 'break-word',
    maxWidth: '400px',
});

export const CommentWrapper = styled('div')({
    display: 'flex',
});
