import CheckIcon from '@mui/icons-material/Check';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, Popover } from '@mui/material';
import { useEffect, useState } from 'react';

import { IFilter } from '../../interfaces/filter';
import {
    getDateEnd,
    getDateStart,
    todayEnd,
    todayStart,
    tomorrowEnd,
    tomorrowStart,
    yesterdayEnd,
    yesterdayStart,
} from '../../utils/helpers/getDaysInMS';
import DatePickers from '../DatePickers';

import './FilterDate.scss';

interface IProps {
    title: string;
    filterItems: IFilter[];
    onChange: (param: { startTime: string | null; endTime: string | null }) => void;
    defaultValue?: { startTime: Date | null; endTime: Date | null; value: { id: string; title: string } };
}

function FilterDate({ title, filterItems, onChange, defaultValue }: IProps) {
    const [isOpen, setIsOpen] = useState(null);
    const [currentSelected, setCurrentSelected] = useState<{ id: string; title: string } | null>(
        defaultValue?.value || null,
    );
    const [currentTime, setCurrentTime] = useState<{ startTime: Date | null; endTime: Date | null }>({
        startTime: null,
        endTime: null,
    });

    const [isInit, setIsInit] = useState(false);

    const [periodStart, setPeriodStart] = useState<Date | null>(defaultValue?.startTime || null);
    const [periodEnd, setPeriodEnd] = useState<Date | null>(defaultValue?.endTime || null);

    useEffect(() => {
        if (!currentSelected || !isInit) return;

        if (currentSelected.title === 'Период') {
            if (!currentTime.startTime || !currentTime.endTime || (!currentTime.endTime as any).$y) return;

            onChange({
                startTime: getDateStart(currentTime.startTime),
                endTime: getDateEnd(currentTime.endTime),
            });
        } else onChange(getDateValues());
    }, [currentSelected, currentTime, currentTime?.endTime, isInit]);

    useEffect(() => {
        if (periodStart && periodEnd) {
            setCurrentTime({
                startTime: periodStart,
                endTime: periodEnd,
            });
        }
        return () => setCurrentTime({ startTime: null, endTime: null });
    }, [periodStart, periodEnd]);

    function getDateValues() {
        switch (currentSelected?.title) {
            case 'Сегодня':
                return { startTime: todayStart, endTime: todayEnd };
            case 'Вчера':
                return { startTime: yesterdayStart, endTime: yesterdayEnd };
            case 'Завтра':
                return { startTime: tomorrowStart, endTime: tomorrowEnd };
            default:
                return { startTime: '', endTime: '' };
        }
    }

    function clearAllFilterState() {
        onChange({ startTime: null, endTime: null });
        setCurrentSelected(null);
        setIsOpen(null);

        setPeriodStart(null);
        setPeriodEnd(null);
    }

    function renderDatePickers() {
        return (
            <DatePickers
                dateStart={periodStart}
                dateEnd={periodEnd}
                setDateStart={(e) => {
                    setIsInit(true);
                    setPeriodStart(e);
                }}
                setDateEnd={(e) => {
                    setIsInit(true);
                    setPeriodEnd(e);
                }}
            />
        );
    }

    return (
        <div className="filter">
            <Button
                variant={isOpen ? 'filterActive' : 'filter'}
                endIcon={<ExpandMoreIcon />}
                onClick={(e: any) => setIsOpen(e.currentTarget)}
                sx={{ textTransform: 'none' }}
            >
                {title}
                {currentSelected ? `: ${currentSelected?.title}` : null}
            </Button>

            <Popover
                open={Boolean(isOpen)}
                anchorEl={isOpen}
                onClose={() => setIsOpen(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                PaperProps={{
                    style: {
                        width: '400px',
                        marginTop: '8px',
                        padding: '10px',
                        border: '1px solid rgba(0, 0, 0, 0.06)',
                        boxShadow: '0px 10px 13px rgba(17, 38, 146, 0.05)',
                        borderRadius: '8px',
                        overflow: 'hidden',
                    },
                }}
            >
                {filterItems.map(({ id, title: itemTitle }) => (
                    <Box
                        key={id}
                        onClick={() => {
                            setIsInit(true);
                            setCurrentSelected({ id, title: itemTitle });
                        }}
                        sx={{
                            padding: '14px',
                            minHeight: '38px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
                            cursor: 'pointer',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                            }}
                        >
                            <Box>{itemTitle}</Box>
                            {currentSelected?.id === id && <CheckIcon />}
                        </Box>
                        {currentSelected?.id === id && itemTitle === 'Период' && renderDatePickers()}
                    </Box>
                ))}
                <Button
                    sx={{
                        padding: '14px',
                    }}
                    onClick={() => clearAllFilterState()}
                    fullWidth
                >
                    Очистить фильтр
                </Button>
            </Popover>
        </div>
    );
}

export default FilterDate;
