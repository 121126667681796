import { TableCell, TableHead, TableRow, styled } from '@mui/material';

export const Head = () => {
    return (
        <TableHead>
            <TableRow>
                <Cell>ДАТА ТРАНЗАКЦИИ</Cell>
                <Cell>ТИП ТРАНЗАКЦИИ</Cell>
                <Cell>СТАТУС ТРАНЗАКЦИИ</Cell>
                <Cell>СТАТУС ИНТЕГРАЦИИ</Cell>
                <Cell>МАСТЕР</Cell>
                <Cell>ИНИЦИАТОР</Cell>
                <Cell>ЗАЯВКА</Cell>
                <Cell>СУММА</Cell>
                <Cell>КОММЕНТАРИЙ</Cell>
            </TableRow>
        </TableHead>
    );
};

const Cell = styled(TableCell)({
    color: '#9E9E9E',
    fontWeight: '600',
    fontSize: '12px',
});
