import Autocomplete from 'components/inputs/Autocomplete';
import { getAddressesDictsThunk } from 'store/actions/dicts';
import { useAppDispatch } from 'store/hooks';
import { addressesDictsSelector, addressesOptionsDictsSelector } from 'store/selectors/dictsSelectors';
import defaultErrorCallback from 'utils/helpers/defaultErrorCallback';
import { getErrorString } from 'utils/helpers/getErrorString';

import { FormControl } from '@mui/material';
import { IOption } from 'interfaces';
// import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-lodash-debounce';

interface IAddressProps {
    label: string;
    name: 'registration_address' | 'juridical_address' | 'address';
    values: {
        address: string;
        fias_id: string;
    };
    disabled?: boolean;
    handleChange: (
        addressName: 'registration_address' | 'juridical_address' | 'address',
        body: {
            address: string;
            fias_id: string;
        },
    ) => void;
}

function Address({ label, name, handleChange, values, disabled }: IAddressProps) {
    const dispatch = useAppDispatch();

    const addressesOptions = useSelector(addressesOptionsDictsSelector);
    const address = useSelector(addressesDictsSelector);
    // const [formValues, setFormValues] = useState<{ lat: null | number; long: null | number }>({
    //     lat: null,
    //     long: null,
    // });

    const changeAddressInputHandler = async (address: string) => {
        if (!address) return;
        try {
            await dispatch(getAddressesDictsThunk({ address }));
        } catch (err) {
            defaultErrorCallback({ errorMessage: getErrorString({ err }) });
        }
    };

    const changeAddressInpDebounced = useDebouncedCallback(changeAddressInputHandler, 800);

    function changeAddressHandler(option: IOption | null) {
        const currentAddress = address?.find((dicts) => dicts.name === option?.label);
        // setFormValues(() => ({
        //     lat: currentAddress?.lat || null,
        //     long: currentAddress?.long || null,
        // }));

        handleChange(name, {
            address: currentAddress?.name || '',
            fias_id: currentAddress?.fias_id || '',
        });
    }

    return (
        <>
            <FormControl fullWidth>
                <Autocomplete
                    disabled={disabled}
                    label={label}
                    name="address.default"
                    inpPlaceholder="Город, улица, дом *"
                    onInpChange={changeAddressInpDebounced}
                    onChange={changeAddressHandler}
                    loadingText={'Загрузка...'}
                    options={addressesOptions || []}
                    isOptionEqualToValue={() => true}
                    defaultValue={{ value: values.fias_id, label: values.address }}
                />
            </FormControl>
            {/* <Grid
                container
                spacing={1}
            >
                <Grid
                    item
                    md={6}
                    xs={12}
                >
                    <TextField
                        name="latitude"
                        label="Широта"
                        disabled
                        fullWidth
                        value={String(formValues.lat || '')}
                    />
                </Grid>
                <Grid
                    item
                    md={6}
                    xs={12}
                >
                    <TextField
                        name="longitude"
                        label="Долгота"
                        disabled
                        fullWidth
                        value={String(formValues.long || '')}
                    />
                </Grid>
            </Grid> */}
        </>
    );
}

export default Address;
