import { FilterOptionsState } from '@mui/base/useAutocomplete/useAutocomplete';
import { Autocomplete as AutocompleteMui, AutocompleteValue, Chip, FormControl, Tooltip } from '@mui/material';
import { FormControlTypeMap } from '@mui/material/FormControl/FormControl';
import { IOption, TErrors } from 'interfaces';
import { ReactNode } from 'react';

import TextField from '../TextField';

interface IProps<TOpt extends IOption, Multiple extends boolean | undefined> {
    name: string;
    label?: string;
    options?: ReadonlyArray<TOpt> | null;
    multiple?: Multiple;
    onChange?: (option: AutocompleteValue<TOpt, Multiple, false, false>) => void;
    onMultipleChange?: (options: TOpt[] | null) => void;
    onInpChange?: (val: string) => void;
    onInpBlur?: () => void;
    onInpFocus?: (val: string) => void;
    errors?: TErrors | null;
    topBtn?: ReactNode;
    disabled?: boolean;
    inpPlaceholder?: string;
    noOptionsText?: string | ReactNode;
    loadingText?: string;
    isLoading?: boolean;
    margin?: FormControlTypeMap['props']['margin'];
    isOptionEqualToValue?: (option: TOpt, value: TOpt) => boolean;
    filterOptions?: (options: TOpt[], state: FilterOptionsState<TOpt>) => TOpt[];
    value?: AutocompleteValue<TOpt, Multiple, false, false> | undefined;
    defaultValue?: AutocompleteValue<TOpt, Multiple, false, false> | undefined;
    open?: boolean;
    tooltipText?: string;
    disableClearable?: boolean;
}

function Autocomplete<TOpt extends IOption, Multiple extends boolean | undefined = false>({
    name,
    label,
    options,
    onChange,
    onInpChange,
    onInpBlur,
    onInpFocus,
    errors,
    topBtn,
    disabled,
    isLoading,
    inpPlaceholder,
    noOptionsText,
    loadingText,
    margin,
    multiple,
    isOptionEqualToValue,
    filterOptions,
    value,
    defaultValue,
    open,
    tooltipText,
    disableClearable,
}: IProps<TOpt, Multiple>) {
    const isOptionEqualToValueDefault = () => {
        return true; //option && selectedValue && option.value === selectedValue.value;
    };

    return (
        <FormControl
            fullWidth
            margin={margin}
        >
            <AutocompleteMui
                renderInput={(params) => {
                    return (
                        <Tooltip
                            title={tooltipText}
                            placement="top"
                        >
                            <div>
                                <TextField
                                    name={name}
                                    labelText={label}
                                    onChange={(e) => {
                                        onInpChange && onInpChange(e.target.value);
                                    }}
                                    onBlur={() => {
                                        onInpBlur && onInpBlur();
                                    }}
                                    onFocus={(e) => {
                                        onInpFocus && onInpFocus(e.target.value);
                                    }}
                                    errors={errors}
                                    placeholder={inpPlaceholder}
                                    params={params}
                                />
                            </div>
                        </Tooltip>
                    );
                }}
                renderOption={(props, option, { index }) => {
                    if (index === 0 && !!topBtn) {
                        return (
                            <div key={option.value}>
                                {topBtn}
                                <li {...props}>{option.label}</li>
                            </div>
                        );
                    }
                    return (
                        <li
                            {...props}
                            key={option.value}
                        >
                            {option.label}
                        </li>
                    );
                }}
                filterOptions={filterOptions || ((opt) => opt)}
                renderTags={(values, getTagProps) => {
                    return values.map((v, index) => {
                        const { className, disabled, key, onDelete, tabIndex } = getTagProps({ index });
                        return (
                            <Chip
                                key={key}
                                label={v.label}
                                className={className}
                                disabled={disabled}
                                onDelete={onDelete}
                                tabIndex={tabIndex}
                            />
                        );
                    });
                }}
                onChange={(_, option) => {
                    onChange && onChange(option);
                }}
                autoHighlight={false}
                getOptionLabel={(option) => option.label}
                getOptionDisabled={(option) => !!option.disabled}
                multiple={multiple}
                isOptionEqualToValue={isOptionEqualToValue || isOptionEqualToValueDefault}
                noOptionsText={noOptionsText || 'Не найдено'}
                loadingText={loadingText || 'Загрузка...'}
                loading={isLoading}
                disabled={disabled}
                options={options || []}
                value={value}
                defaultValue={defaultValue}
                open={open}
                disableClearable={disableClearable}
                sx={{
                    '& .MuiAutocomplete-option[aria-selected="true"]': {
                        backgroundColor: '#000',
                    },
                }}
            />
        </FormControl>
    );
}

export default Autocomplete;
