import TextField from 'components/inputs/TextField';
import { fetchHistoryComments } from 'pages/issue/comments-history/store';
import { getHistoryThunk } from 'store/actions/history';
import { putIssueAppointmentThunk } from 'store/actions/issues';
import { useAppDispatch } from 'store/hooks';
import { issueSelector } from 'store/selectors/issuesSelectors';
import defaultErrorCallback from 'utils/helpers/defaultErrorCallback';
import { getErrorString } from 'utils/helpers/getErrorString';

import CloseIcon from '@mui/icons-material/Close';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { TErrors } from 'interfaces';
import { ITime } from 'interfaces/create/createIssue';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import './ReasonModel.scss';

interface IFormValues {
    comment?: string | null;
}

interface IProp {
    isOpen: boolean;
    setIsOpen: (val: boolean) => void;
    setIsParentOpen: (val: boolean) => void;
    timeWindow: {
        date: string;
        time: ITime;
    } | null;
}

function ReasonModal({ isOpen, setIsOpen, setIsParentOpen, timeWindow }: IProp) {
    const dispatch = useAppDispatch();

    const issue = useSelector(issueSelector);

    const [formValues, setFormValues] = useState<IFormValues | null>(null);
    const [errors, setErrors] = useState<TErrors | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    function handleClose() {
        setIsOpen(false);
    }

    useEffect(() => {
        if (isOpen) {
            setFormValues({
                comment: '',
            });
        }
        setErrors(null);
    }, [isOpen]);

    async function submitHandler() {
        if (!issue || !formValues) return;

        if (validator()) {
            try {
                setIsLoading(true);
                await dispatch(
                    putIssueAppointmentThunk({
                        issueId: issue.id,
                        data: { comment: formValues.comment!, time_window: timeWindow! },
                    }),
                );
                await dispatch(fetchHistoryComments(issue?.id));
                await dispatch(getHistoryThunk(issue.id));
                handleClose();
                setIsParentOpen(false);
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            } finally {
                setIsLoading(false);
            }
        }
    }

    function onChangeComment(e: React.ChangeEvent<HTMLInputElement>) {
        setFormValues({ ...(formValues || {}), comment: e.target.value || null });
    }

    const validator = useCallback(() => {
        const errs = {} as TErrors;

        if (!formValues?.comment) {
            errs.comment = { message: 'Обязательное поле' };
        }

        setErrors(errs);
        return !Object.values(errs).some((val) => val);
    }, [formValues]);

    useEffect(() => {
        if (errors) {
            validator();
        }
    }, [formValues]);

    return (
        <Dialog
            open={isOpen}
            maxWidth="sm"
            fullWidth
            scroll="body"
            disableEscapeKeyDown
        >
            <DialogTitle>
                <Box className="reason-dialog-title">
                    <div className="close-icon">
                        <CloseIcon onClick={handleClose} />
                    </div>
                    <DriveFileRenameOutlineOutlinedIcon className="title-icon" />{' '}
                    <div className="title-text">Изменение адреса вызова</div>
                </Box>
            </DialogTitle>
            <DialogContent>
                <div className="reason-form-wrapper">
                    <div className="info-text">Вы изменили дату или время вызова, нужно указать причину</div>

                    <TextField
                        name="comment"
                        placeholder="Комментарий *"
                        fullWidth
                        params={{ multiline: true, minRows: 4, margin: 'normal' }}
                        errors={errors}
                        onChange={onChangeComment}
                    />

                    <div className="buttons-wrapper">
                        <Button
                            variant="outlined"
                            onClick={handleClose}
                        >
                            Отмена
                        </Button>

                        <LoadingButton
                            variant="contained"
                            loading={isLoading}
                            onClick={submitHandler}
                        >
                            Сохранить
                        </LoadingButton>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}

export default memo(ReasonModal);
