import { styled } from '@mui/material';

export const HistoryItemWrapper = styled('div')({
    padding: '5px 5px 10px',
});

export const HistoryItem = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',

    position: 'relative',
});

export const Statuses = styled('div')({
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: '10px',
    rowGap: '5px',
});

export const EditIconWrapper = styled('span')({
    position: 'absolute',
    right: '7px',
});

export const Comment = styled('span')({
    fontSize: '12px',
    overflowWrap: 'break-word',
    maxWidth: '160px',
});
