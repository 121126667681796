import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import { Box } from '@mui/material';
import cn from 'classnames';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { EWorkerStatus, EWorkerStatusColors } from '../../../../enums/workers.enum';
import useOutsideClick from '../../../../hooks/useOutsideClick';
import { WorkerStatusesMock } from '../../../../mockData/workers';
import './Verification.scss';
import { editWorkerInfoThunk } from '../../../../store/actions/edit/editWorkerInfo';
import { getWorkerThunk } from '../../../../store/actions/worker';
import { useAppDispatch } from '../../../../store/hooks';
import { workerDetailSelector } from '../../../../store/selectors/workerSelectors';

function Verification() {
    const dispatch = useAppDispatch();

    const worker = useSelector(workerDetailSelector);

    const statusInfo = worker && WorkerStatusesMock[worker.status];

    const canActivateWorker = worker?.verify_status === 'success';

    const [isOpen, setIsOpen] = useState(false);
    const ref = useOutsideClick(() => {
        setIsOpen(false);
    });

    async function editWorkerStatus(status: EWorkerStatus) {
        if (worker) {
            await dispatch(editWorkerInfoThunk(worker.id, { status }));
            await dispatch(getWorkerThunk(worker.id));
            setIsOpen(false);
        }
    }

    return (
        <div>
            {statusInfo && (
                <div className="verification-block">
                    <div
                        className="status-title"
                        ref={ref}
                    >
                        <Box
                            className="open-put-statuses-button"
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            <div
                                className="status-icon-title"
                                style={{
                                    color:
                                        worker?.status === 'connected_working_ready' && !worker?.is_active
                                            ? 'rgb(239, 108, 0)'
                                            : statusInfo.color,
                                }}
                            >
                                <PanoramaFishEyeIcon
                                    style={{
                                        width: 11,
                                        height: 11,
                                        stroke:
                                            worker?.status === 'connected_working_ready' && !worker?.is_active
                                                ? 'rgb(239, 108, 0)'
                                                : statusInfo.color,
                                        strokeWidth: 3,
                                    }}
                                />
                                {worker?.status === 'connected_working_ready' && !worker?.is_active
                                    ? 'Неактивен'
                                    : statusInfo.title}
                            </div>
                            {!!statusInfo.activePutStatuses?.length && (
                                <ExpandMoreIcon
                                    className={cn('expand-more-icon', { isOpen })}
                                    sx={{
                                        rotate: isOpen ? '0deg' : '180deg',
                                        color: isOpen
                                            ? worker?.status === 'connected_working_ready' && !worker?.is_active
                                                ? 'rgb(239, 108, 0)'
                                                : statusInfo.color
                                            : 'initial',
                                    }}
                                />
                            )}
                        </Box>
                        {!!statusInfo.activePutStatuses?.length && (
                            <div className={cn('put-statuses', { isOpen })}>
                                {statusInfo.activePutStatuses.map((putStatus) => {
                                    const disableSuccessStatuses =
                                        (!canActivateWorker && putStatus === EWorkerStatus.Verified) ||
                                        (!canActivateWorker && putStatus === EWorkerStatus.ConnectedWorkingNotReady) ||
                                        (!canActivateWorker && putStatus === EWorkerStatus.ConnectedWorkingReady);

                                    const { title, color } = WorkerStatusesMock[putStatus];

                                    return disableSuccessStatuses ? (
                                        <Box
                                            key={putStatus}
                                            className="status-icon-title"
                                            style={{ color: EWorkerStatusColors.Manatee, cursor: 'default' }}
                                        >
                                            <PanoramaFishEyeIcon
                                                style={{
                                                    width: 11,
                                                    height: 11,
                                                    stroke: EWorkerStatusColors.Manatee,
                                                    strokeWidth: 3,
                                                }}
                                            />
                                            {title}
                                        </Box>
                                    ) : (
                                        <Box
                                            key={putStatus}
                                            className="status-icon-title"
                                            style={{ color }}
                                            onClick={() => editWorkerStatus(putStatus)}
                                        >
                                            <PanoramaFishEyeIcon
                                                style={{
                                                    width: 11,
                                                    height: 11,
                                                    stroke: color,
                                                    strokeWidth: 3,
                                                }}
                                            />
                                            {title}
                                        </Box>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                    <div className="status-description">{WorkerStatusesMock[worker.status]?.description}</div>
                </div>
            )}
        </div>
    );
}

export default Verification;
