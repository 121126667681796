import { issueStatusesMock } from 'mockData/issues';
import { ThreeDots } from 'static/Icons/ThreeDots';
import { useAppDispatch } from 'store/hooks';
import { dateFormatter } from 'utils/helpers/dateFormatter';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Chip, Divider } from '@mui/material';
import { ICommentsHistory } from 'interfaces/history';
import { FC } from 'react';

import { Comment, CommentWrapper, HistoryItemWrapper, Statuses } from './styles';

import { setChooseDelete } from '../../store';
import { HistoryWrapper, ThreeDotsWrapper, TimeWrapper, UserDataWrapper } from '../styles';

type Props = {
    historyItem: ICommentsHistory;
    setIsHistoryComment: (value: any) => void;
    setOpenMenu: (value: any) => void;
};

export const OpenedHistoryItem: FC<Props> = ({ historyItem, setIsHistoryComment, setOpenMenu }) => {
    const dispatch = useAppDispatch();

    const format: Intl.DateTimeFormatOptions = {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    };

    const formatForChecking: Intl.DateTimeFormatOptions = {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
    };

    const getTimeWindow = (start: string, end: string) => {
        if (!start || !end) return;

        const date = start.split('T')[0].replaceAll('-', '.');
        const times = `${start.split('T')[1].slice(0, 5)}−${end.split('T')[1].slice(0, 5)}`;

        const temp = date.split('.');
        const year = temp[0].split('');
        year.splice(0, 2);
        temp[0] = year.join('');

        return `${temp.reverse().join('.')} ${times}`;
    };

    const isHistoryCommentUpdated =
        dateFormatter(historyItem.data?.comments?.[0]?.created_at as string, {}, formatForChecking) !==
        dateFormatter(historyItem.data?.comments?.[0]?.updated_at as string, {}, formatForChecking);

    const isCommentUpdated =
        dateFormatter(historyItem.data?.created_at, {}, formatForChecking) !==
        dateFormatter(historyItem.data?.updated_at as string, {}, formatForChecking);

    return (
        <HistoryItemWrapper
            sx={{
                display:
                    historyItem?.data?.trigger === 'create_issue' ||
                    historyItem?.data?.trigger === 'update_issue' ||
                    historyItem?.data?.trigger === 'worker'
                        ? 'none'
                        : 'block',
            }}
            key={historyItem.data.id}
        >
            <UserDataWrapper>
                <div>
                    {historyItem?.data?.user?.first_name} {historyItem?.data?.user?.last_name}
                </div>
                <ThreeDotsWrapper
                    onClick={(e) => {
                        setOpenMenu(e.target);
                        if (historyItem.object_type === 'history') {
                            dispatch(setChooseDelete(historyItem.data?.comments?.[0]?.id || historyItem.data?.id));
                            setIsHistoryComment(historyItem.data?.comments?.[0] || {});
                        } else {
                            dispatch(setChooseDelete(historyItem.data?.id));
                            setIsHistoryComment(false);
                        }
                    }}
                >
                    <ThreeDots height={16} />
                </ThreeDotsWrapper>
            </UserDataWrapper>

            <HistoryWrapper>
                {historyItem.object_type === 'comment' ? <Comment>{historyItem.data?.comment}</Comment> : null}

                {historyItem.object_type === 'history' && historyItem?.data?.trigger === 'status' ? (
                    <div>
                        <Statuses>
                            <Chip
                                label={issueStatusesMock[historyItem.data?.old_value]?.title}
                                sx={{
                                    fontWeight: '600',
                                    backgroundColor: issueStatusesMock[historyItem.data?.old_value]?.colorHex,
                                    color: `${issueStatusesMock[historyItem.data?.old_value]?.colorText} !important`,
                                }}
                                size={'small'}
                            />
                            <ArrowForwardIcon color="disabled" />
                            <Chip
                                label={issueStatusesMock[historyItem.data?.new_value]?.title}
                                sx={{
                                    fontWeight: '600',
                                    backgroundColor: `${issueStatusesMock[historyItem.data?.new_value]?.colorHex}`,
                                    color: `${issueStatusesMock[historyItem.data?.new_value]?.colorText} !important`,
                                }}
                                size={'small'}
                            />
                        </Statuses>
                        {historyItem?.data?.comments?.length
                            ? historyItem?.data?.comments.map((commentItem) => {
                                  return (
                                      <CommentWrapper key={commentItem?.id}>
                                          <Comment>{commentItem?.comment}</Comment>
                                      </CommentWrapper>
                                  );
                              })
                            : null}
                    </div>
                ) : null}

                {historyItem.object_type === 'history' && historyItem?.data?.trigger === 'time_windows' ? (
                    <div>
                        <Statuses>
                            <Chip
                                label={getTimeWindow(
                                    historyItem?.data?.extra_data?.find((data) => data.type === 'old')?.start_datetime!,
                                    historyItem?.data?.extra_data?.find((data) => data.type === 'old')?.end_datetime!,
                                )}
                                size={'small'}
                            />
                            <ArrowForwardIcon color="disabled" />
                            <Chip
                                label={getTimeWindow(
                                    historyItem?.data?.extra_data?.find((data) => data.type === 'new')?.start_datetime!,
                                    historyItem?.data?.extra_data?.find((data) => data.type === 'new')?.end_datetime!,
                                )}
                                size={'small'}
                            />
                        </Statuses>
                        {historyItem?.data?.comments?.length
                            ? historyItem?.data?.comments.map((commentItem) => {
                                  return (
                                      <CommentWrapper key={commentItem?.id}>
                                          <Comment>{commentItem?.comment}</Comment>
                                      </CommentWrapper>
                                  );
                              })
                            : null}
                    </div>
                ) : null}
            </HistoryWrapper>

            <TimeWrapper>
                <span>{dateFormatter(historyItem.data?.created_at, {}, format)}</span>

                {historyItem?.data?.trigger ? (
                    isHistoryCommentUpdated ? (
                        <span>
                            , изменено{' '}
                            {dateFormatter(historyItem.data?.comments?.[0]?.updated_at as string, {}, format)}
                        </span>
                    ) : null
                ) : isCommentUpdated ? (
                    <span>, изменено {dateFormatter(historyItem.data?.updated_at as string, {}, format)}</span>
                ) : null}
            </TimeWrapper>

            <Divider sx={{ marginTop: '5px' }} />
        </HistoryItemWrapper>
    );
};
