import MainLayout from 'layouts/MainLayout';
import CreateIssueCopy from 'pages/issue/CreateIssueCopy';
import { setTotalUnseenCountIssuesAction } from 'store/actions/chats';
import { getIssueThunk, setIssueAction } from 'store/actions/issues';
import { setSetSelectedOrdersAction } from 'store/actions/orders';
import { useAppDispatch } from 'store/hooks';
import { issueSelector } from 'store/selectors/issuesSelectors';
import defaultErrorCallback from 'utils/helpers/defaultErrorCallback';
import { getErrorString } from 'utils/helpers/getErrorString';

import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import IssueCommentsWrapper from './comments-history';
import { fetchHistoryComments } from './comments-history/store';
import CreateIssueReminder from './CreateIssueReminder';
import EditIssueStatus from './EditIssueStatus';
import IssueDetail from './IssueDetail';
import './Issue.scss';

function IssuePage() {
    const { issueId } = useParams();

    const dispatch = useAppDispatch();

    const issue = useSelector(issueSelector);

    useEffect(() => {
        dispatch(setTotalUnseenCountIssuesAction(issue?.count_unseen_messages || null));
    }, [issue]);

    useEffect(() => {
        return () => {
            dispatch(setIssueAction(null));
            dispatch(setSetSelectedOrdersAction(null));
        };
    }, []);

    useEffect(() => {
        async function handle() {
            try {
                if (!issueId) {
                    console.error('Error!!! !issueId IssuePage');
                    return;
                }
                await dispatch(getIssueThunk(issueId));
                await dispatch(fetchHistoryComments(issueId));
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            }
        }

        handle();
    }, [issueId]);

    return (
        <MainLayout>
            <div className="issue-page-wrapper">
                <div className="issue-page-header">
                    <CreateIssueReminder />
                    <CreateIssueCopy />
                    <EditIssueStatus currentStatus={issue?.status || null} />
                </div>
                <div className="issue-page-content">
                    <IssueDetail />
                    <IssueCommentsWrapper />
                </div>
            </div>
        </MainLayout>
    );
}

export default IssuePage;
