import { issuesApi } from 'api';
import Autocomplete from 'components/inputs/Autocomplete';
import { issueCreatedHowYouFindOutMock } from 'mockData/issues';
import { getIssueThunk } from 'store/actions/issues';
import { useAppDispatch } from 'store/hooks';
import { issueSelector } from 'store/selectors/issuesSelectors';

import CloseIcon from '@mui/icons-material/Close';
import EditSharpIcon from '@mui/icons-material/EditSharp';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { ButtonsContainer, CloseIconWrapper, EditIconButton, TitleIcon, TitleWrapper } from './styles';

export const WhereFind = () => {
    const dispatch = useAppDispatch();

    const issue = useSelector(issueSelector);

    const [isOpen, setOpen] = useState<boolean>(false);
    const [form, setForm] = useState<string | undefined>(issue?.how_you_find_out || '');

    const changeHowYouFindOutHandler = () => {
        issuesApi
            .putIssue(issue?.id as string, { how_you_find_out: form })
            .then(() => {
                dispatch(getIssueThunk(issue?.id as string));
                enqueueSnackbar('Изменения сохранены', { variant: 'success' });
            })
            .catch((e) => {
                enqueueSnackbar(e.response.data.error?.message || e.message, { variant: 'error' });
            })
            .finally(() => setOpen(false));
    };

    const handleClose = () => {
        setOpen(false);
        setForm(issue?.how_you_find_out);
    };

    return (
        <div>
            {issue?.how_you_find_out ? <div>{issue?.how_you_find_out}</div> : null}
            <EditIconButton onClick={() => setOpen(true)}>
                <EditSharpIcon />
            </EditIconButton>
            <Dialog
                maxWidth="sm"
                fullWidth
                open={isOpen}
                scroll="body"
                disableEscapeKeyDown
            >
                <DialogTitle>
                    <TitleWrapper>
                        <TitleIcon />
                        <Typography variant="h6">Откуда о нас узнали</Typography>
                    </TitleWrapper>
                    <CloseIconWrapper onClick={handleClose}>
                        <CloseIcon />
                    </CloseIconWrapper>
                </DialogTitle>
                <DialogContent>
                    <Autocomplete
                        disableClearable
                        name="how_you_find_out"
                        margin="dense"
                        label={'Откуда о нас узнали'}
                        options={issueCreatedHowYouFindOutMock.map((item) => ({ value: item, label: item }))}
                        onChange={(option) => setForm(option?.value)}
                        defaultValue={{
                            value: issue?.how_you_find_out ?? '',
                            label: issue?.how_you_find_out ?? '',
                        }}
                        isOptionEqualToValue={() => true}
                    />
                </DialogContent>
                <DialogActions>
                    <ButtonsContainer>
                        <Button onClick={handleClose}>Отменить</Button>
                        <Button
                            variant="contained"
                            onClick={changeHowYouFindOutHandler}
                            disabled={!form}
                        >
                            Сохранить
                        </Button>
                    </ButtonsContainer>
                </DialogActions>
            </Dialog>
        </div>
    );
};
