export const calculatePercentage = (firstNum: number, secondNum: number | null) => {
    if (secondNum === 0 || firstNum === 0) return '0';
    if (!secondNum || !firstNum) return ' ';

    const result = Number.isInteger((firstNum / secondNum) * 100)
        ? (firstNum / secondNum) * 100
        : ((firstNum / secondNum) * 100).toFixed(2);

    return result;
};
