export const EditSmall = () => {
    return (
        <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.59504 3.54324L3.63797 9.52993C3.32817 9.83149 2.921 10 2.49613 10L0.416022 10C0.300952 10 0.194734 9.95565 0.115071 9.87583C0.0354069 9.79601 8.4709e-07 9.68958 8.3701e-07 9.57428L0.0531098 7.47228C0.0619612 7.05543 0.23014 6.66519 0.52224 6.37251L4.74441 2.14191C4.81523 2.07095 4.93915 2.07095 5.00996 2.14191L6.49082 3.61685C6.58818 3.71352 6.72981 3.7765 6.88028 3.7765C7.20779 3.7765 7.46448 3.51042 7.46448 3.19113C7.46448 3.03148 7.40252 2.88958 7.30516 2.78315C7.2786 2.74767 5.86856 1.34368 5.86856 1.34368C5.78004 1.25499 5.78004 1.10421 5.86856 1.01552L6.46161 0.412416C7.0104 -0.137473 7.89555 -0.137473 8.44435 0.412416L9.59504 1.56541C10.135 2.10643 10.135 2.99335 9.59504 3.54324Z"
                fill="#878EA5"
            />
        </svg>
    );
};
