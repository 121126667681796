import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import { IconButton, styled } from '@mui/material';

export const EditIconButton = styled(IconButton)({
    position: 'absolute',
    top: '-8px',
    right: '0',

    svg: {
        fontSize: '15px',
    },
});

export const ButtonsContainer = styled('div')({
    display: 'flex',
    gap: '10px',
});

export const CloseIconWrapper = styled('div')({
    position: 'absolute',
    top: '20px',
    right: '15px',
    cursor: 'pointer',
});

export const TitleIcon = styled(DriveFileRenameOutlineOutlinedIcon)({
    marginRight: '30px',
    width: '36px',
    height: '36px',
    color: '#48c1f4',
});

export const TitleWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
});
