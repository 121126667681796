import { issuesApi } from 'api';
import { getIssueThunk } from 'store/actions/issues';
import { useAppDispatch } from 'store/hooks';
import { issueSelector } from 'store/selectors/issuesSelectors';

import { ICommentsHistory } from 'interfaces/history';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { DefaultHistoryItem } from './default-history-item';
import { OpenedHistoryItem } from './opened-history-item';

import { AddComment } from '../components/modals/add-comment';
import { Confirm } from '../components/modals/confirm';
import { EditComment } from '../components/modals/edit-comment';
import { EditStatus } from '../components/modals/edit-status';
import { Menu } from '../components/modals/menu';
import { fetchHistoryComments, setChooseDelete } from '../store';
import { commentsHistorySelector } from '../store/selectors';

export const IssueHistory = () => {
    const dispatch = useAppDispatch();

    const issue = useSelector(issueSelector);
    const { commentsHistory, chooseDelete, historyModalIsOpen } = useSelector(commentsHistorySelector);

    const [isOpenMenu, setOpenMenu] = useState<any>(false);
    const [isOpenEditComment, setOpenEditComment] = useState<boolean>(false);
    const [isOpenEditStatus, setOpenEditStatus] = useState<boolean>(false);
    const [isOpenConfirm, setOpenConfirm] = useState<boolean>(false);
    const [isOpenChangeStatus, setOpenChangeStatus] = useState<boolean>(false);

    const [isHistoryComment, setIsHistoryComment] = useState<any>(false);

    const addComment = (value: string) => {
        if (issue) {
            issuesApi
                .addComment(issue.id, { comment: value, history_id: chooseDelete })
                .then(() => {
                    dispatch(getIssueThunk(issue.id));
                    dispatch(fetchHistoryComments(issue.id));
                    dispatch(setChooseDelete(null));

                    enqueueSnackbar('Комментарий добавлен', { variant: 'success' });
                })
                .catch((e) => {
                    enqueueSnackbar(e.response.data.error?.message || e.message, { variant: 'error' });
                })
                .finally(() => {
                    setOpenEditStatus(false);
                });
        }
    };

    const editComment = (value: string) => {
        if (issue) {
            issuesApi
                .editComment(issue.id, chooseDelete as string, { comment: value })
                .then(() => {
                    dispatch(getIssueThunk(issue.id));
                    dispatch(fetchHistoryComments(issue.id));
                    dispatch(setChooseDelete(null));

                    enqueueSnackbar('Комментарий изменен', { variant: 'success' });
                })
                .catch((e) => {
                    enqueueSnackbar(e.response.data.error?.message || e.message, { variant: 'error' });
                })
                .finally(() => {
                    setOpenEditComment(false);
                });
        }
    };

    const deleteComment = () => {
        if (issue) {
            issuesApi
                .deleteComment(issue.id, chooseDelete as string)
                .then(() => {
                    dispatch(getIssueThunk(issue.id));
                    dispatch(fetchHistoryComments(issue.id));
                    dispatch(setChooseDelete(null));

                    enqueueSnackbar('Комментарий удален', { variant: 'success' });
                })
                .catch((e) => {
                    enqueueSnackbar(e.response.data.error?.message || e.message, { variant: 'error' });
                })
                .finally(() => {
                    setOpenConfirm(false);
                });
        }
    };

    return (
        <div style={{ marginTop: '15px' }}>
            {commentsHistory?.length ? (
                commentsHistory?.map((item: ICommentsHistory) => {
                    return (
                        <div
                            key={item.data.id}
                            style={{
                                display:
                                    item?.object_type === 'comment' ||
                                    item?.data?.trigger === 'status' ||
                                    item?.data?.trigger === 'time_windows'
                                        ? 'block'
                                        : 'none',
                            }}
                        >
                            {historyModalIsOpen ? (
                                <OpenedHistoryItem
                                    key={item.data.id}
                                    historyItem={item}
                                    setOpenMenu={setOpenMenu}
                                    setIsHistoryComment={setIsHistoryComment}
                                />
                            ) : (
                                <DefaultHistoryItem
                                    key={item.data.id}
                                    historyItem={item}
                                    setOpenMenu={setOpenMenu}
                                    setIsHistoryComment={setIsHistoryComment}
                                />
                            )}
                        </div>
                    );
                })
            ) : (
                <p className="plug">Статусы пока не изменялись</p>
            )}

            <Menu
                isHistoryComment={isHistoryComment}
                isOpenMenu={isOpenMenu}
                setOpenMenu={setOpenMenu}
                setOpenEditComment={setOpenEditComment}
                setOpenEditStatus={setOpenEditStatus}
                setOpenConfirm={setOpenConfirm}
            />
            <EditComment
                editComment={editComment}
                isOpenEditComment={isOpenEditComment}
                setOpenEditComment={setOpenEditComment}
            />
            <AddComment
                addComment={addComment}
                isOpenDialog={isOpenEditStatus}
                setOpenDialog={setOpenEditStatus}
            />
            <Confirm
                deleteComment={deleteComment}
                isOpenConfirm={isOpenConfirm}
                setOpenConfirm={setOpenConfirm}
            />
            <EditStatus
                isOpenChangeStatus={isOpenChangeStatus}
                setOpenChangeStatus={setOpenChangeStatus}
            />
        </div>
    );
};
